import React, { useState } from 'react';
import { Modal, Input, Button, CheckBox } from '@/components/_root';
import axios from 'axios';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  downloadLink?: string;
}

const BookDownloadModal = ({ isOpen, onClose, downloadLink }: Props): JSX.Element => {
  const [firstname, setFirstname] = useState<string>('');
  const [lastname, setLastname] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [jobtitle, setJobtitle] = useState<string>('');
  const [formStatus, setFormStatus] = useState<'pending' | 'loading' | 'success' | 'error'>(
    'pending',
  );
  const [isChecked, setChecked] = useState<boolean>(true);

  const onDownload = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    const submitData = {
      firstname: firstname.trim(),
      lastname: lastname.trim(),
      email: email.trim(),
      company: company.trim(),
      jobtitle: jobtitle.trim(),
    };
    if (!submitData.firstname || !submitData.lastname || !submitData.email || !submitData.company || !submitData.jobtitle) {
      alert('Please provide valid data');
      return;
    }

    setFormStatus('loading');

    const payload = {
      submittedAt: new Date().getTime(),
      fields: [
        {
          name: 'firstname',
          value: submitData.firstname,
        },
        {
          name: 'lastname',
          value: submitData.lastname,
        },
        {
          name: 'email',
          value: submitData.email,
        },
        {
          name: 'company',
          value: submitData.company,
        },
        {
          name: 'jobtitle',
          value: submitData.jobtitle,
        },
      ],
      context: {
        pageUri: 'www.riseatseven.com',
        pageName: 'rise-at-seven',
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: true,
          text: 'I agree to allow Example Company to store and process my personal data.',
          communications: [
            {
              value: true,
              subscriptionTypeId: 999,
              text: 'I agree to receive marketing communications from Example Company.',
            },
          ],
        },
      },
    };

    axios
      .post(
        `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${process.env.GATSBY_HUBSPOT_BOOK_FORM}`,
        payload,
      )
      .then((response: any): void => {
        setFormStatus('success');
        if (downloadLink) window.open(downloadLink, '_blank');
      })
      .catch((error: any): void => {
        setFormStatus('error');
      });
  };

  let modalBody = <div />;

  switch (formStatus) {
    case 'loading':
      modalBody = <BookDownloadModal.Loading />;
      break;
    case 'success':
      modalBody = <BookDownloadModal.Success />;
      break;
    case 'error':
      modalBody = <BookDownloadModal.Error />;
      break;
    default:
      modalBody = (
        <BookDownloadModal.PendingBody
          firstname={firstname}
          lastname={lastname}
          email={email}
          company={company}
          jobtitle={jobtitle}
          setFirstname={setFirstname}
          setLastname={setLastname}
          setEmail={setEmail}
          setCompany={setCompany}
          setJobtitle={setJobtitle}
          onDownload={onDownload}
          isChecked={isChecked}
          setChecked={setChecked}
        />
      );
  }

  return (
    <Modal isOpen={isOpen} header={'Get Book of Fame'} onClose={onClose}>
      {modalBody}
    </Modal>
  );
};

BookDownloadModal.PendingBody = ({
  firstname,
  lastname,
  email,
  company,
  jobtitle,
  setFirstname,
  setLastname,
  setEmail,
  setCompany,
  setJobtitle,
  onDownload,
  isChecked,
  setChecked,
}: any): JSX.Element => {
  return (
    <form onSubmit={onDownload}>
      <Input
        placeholder="First Name"
        value={firstname}
        onChange={setFirstname}
        className={`mb-6`}
        mode={'light'}
        isBlock
      />
      <Input
        placeholder="Last Name"
        value={lastname}
        onChange={setLastname}
        className={`mb-6`}
        mode={'light'}
        isBlock
      />
      <Input
        placeholder="Email"
        value={email}
        onChange={setEmail}
        className={`mb-8`}
        mode={'light'}
        isBlock
      />
      <Input
        placeholder="Company"
        value={company}
        onChange={setCompany}
        className={`mb-6`}
        mode={'light'}
        isBlock
      />
      <Input
        placeholder="Job Title"
        value={jobtitle}
        onChange={setJobtitle}
        className={`mb-6`}
        mode={'light'}
        isBlock
      />
      {/* <div className={'-mt-2 pb-6 text-gray-800'}>
        <p className={'pb-2 font-semibold'}>Can we get in touch?</p>
        <p className={'text-xs pb-4'}>
          We’d love to be share our latest ideas, campaigns and news with you. Check the box below
          to get them sent directly to your inbox. We’re committed to protecting your details, and
          will never share them with other companies.
        </p>
        <CheckBox
          label={'Yes please, I’d like to hear from you.'}
          isChecked={isChecked}
          onChange={() => setChecked(!isChecked)}
        />
  </div> */}
      <Button onClick={onDownload} hoverVersion={'bordered'} block>
        Download now!
      </Button>
    </form>
  );
};
BookDownloadModal.Loading = (): JSX.Element => (
  <div className={`h-40 flex items-center justify-center`}>Form Submitting...</div>
);

BookDownloadModal.Success = (): JSX.Element => (
  <div className={`h-40 flex items-center justify-center`}>
    Form submitted Successfully! Downloading...
  </div>
);

BookDownloadModal.Error = (): JSX.Element => (
  <div className={`h-40 flex items-center justify-center`}>
    Something went wrong, please try again later...
  </div>
);

export default BookDownloadModal;
