import React from 'react';
import { Button } from '@/components/_root';

interface Props {
  description?: string;
  button?: { url: string; title?: string };
}

export const ResultLikeThis = ({ description, button }: Props): JSX.Element => (
  <>
    <p className="pb-4">{description}</p>
    <Button size="large" href={button?.url}>
      {button?.title ?? 'Get in touch'}
    </Button>
  </>
);
