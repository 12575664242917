import React from 'react';
import getGradientSteps from '@/utils/helper/getGradientSteps';

interface Insight {
  value: string;
  label: string;
}

interface Props {
  insights: Insight[];
}

const ProjectInsights = ({ insights }: Props): JSX.Element => {
  const colors: string[] = getGradientSteps(
    ['#9542F6', '#EA36A8', '#F09A52'],
    insights.length > 3 ? insights.length + 1 : 4,
  );

  return (
    <div className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8`}>
      {insights.map(
        (insight: Insight, index: number): JSX.Element => (
          <div key={index} className={`text-center`}>
            <h2
              className={`uppercase pb-1`}
              style={{
                background: `-webkit-linear-gradient(left,${[
                  colors[index],
                  colors[index + 1],
                ].join()})`,
                WebkitTextFillColor: 'transparent',
                WebkitBackgroundClip: 'text',
              }}
            >
              {insight.value}
            </h2>
            <div className={`capitalize`}>{insight.label}</div>
          </div>
        ),
      )}
    </div>
  );
};

export default ProjectInsights;
