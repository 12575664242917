export const getCarouselItemDimension = (
  min: number,
  max: number,
  breakpoint: 'sm' | 'md' | 'lg' | 'xl',
): number => {
  const diff = max - min;
  let breakPointMultiplier = 1;
  if (breakpoint === 'lg') breakPointMultiplier = 0.67;
  if (breakpoint === 'md') breakPointMultiplier = 0.33;
  if (breakpoint === 'sm') breakPointMultiplier = 0.0;

  return min + diff * breakPointMultiplier;
};
