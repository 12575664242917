import React, { useMemo } from 'react';
import { Swiper, SwiperItem, Container, Image, HeaderText } from '@/components/_root';
import { ICaseStudy } from '@/utils/types';
import { getCarouselItemDimension } from '@/utils/carousel';
import { useViewport } from '@/components/_hook';
import { Link } from 'gatsby';

const CAROUSEL_ITEM_MIN_HEIGHT = 374;
const CAROUSEL_ITEM_MAX_HEIGHT = 438;
const SUMMARY_CHAR_LIMIT = 100;

interface Props {
  isDark?: boolean;
  sectionTitle: {
    solid?: string;
    outlined?: string;
    isInline?: boolean;
    className?: string;
  };
  caseStudies: ICaseStudy[];
  sectionClassNames?: string;
  containerClasses?: string;
}

const CaseStudiesSlider = ({
  isDark = false,
  sectionTitle,
  caseStudies,
  sectionClassNames = '',
  containerClasses = '',
}: Props): JSX.Element => {
  const { container } = useViewport();

  const itemHeight = useMemo(
    () => getCarouselItemDimension(CAROUSEL_ITEM_MIN_HEIGHT, CAROUSEL_ITEM_MAX_HEIGHT, container),
    [container],
  );
  return (
    <div className={`${isDark ? 'bg-dominant text-white' : ''} pb-16 ${containerClasses}`}>
      <Container className={`py-0`}>
        <div className={`pb-8`}>
          <HeaderText
            mode={`${isDark ? 'dark' : 'light'}`}
            size="h2"
            className={`${sectionTitle.isInline ? 'inline' : ''} ${sectionTitle.className}`}
          >
            {sectionTitle.solid}
          </HeaderText>
          <HeaderText.Outline
            mode={`${isDark ? 'dark' : 'light'}`}
            size="h2"
            className={`${sectionTitle.isInline ? 'inline' : ''} ${sectionTitle.className}`}
          >
            {sectionTitle.outlined}
          </HeaderText.Outline>
        </div>
      </Container>
      <Swiper haveOffset showBleeding showNavigation isLoop={false}>
        {caseStudies.map(({ node }: any, index: number) => (
          <SwiperItem key={index}>
            <Link to={node?.slug?.current && `/work/${node?.slug?.current}`}>
              <Image
                src={node?.thumbnail}
                alt={node?.case_study_title}
                className={`rounded object-cover mb-12 w-full overflow-hidden`}
                style={{ height: itemHeight }}
              />
              <h4 className={`truncate`}>{node?.case_study_title}</h4>
              <p className={`mt-4 normal-case`}>
                {node?.excerpt?.length > SUMMARY_CHAR_LIMIT
                  ? `${node?.excerpt?.substring(0, SUMMARY_CHAR_LIMIT)} ...`
                  : node?.excerpt}
              </p>
            </Link>
          </SwiperItem>
        ))}
      </Swiper>
    </div>
  );
};

export default CaseStudiesSlider;
