/* eslint-disable react-hooks/rules-of-hooks */
import React, { ReactNode } from 'react';
import { Button, Container } from '@/components/_root';
import {
  BannerImageSection,
  BodyButtonWrapper,
  BodyTextContent,
  BodyWrapper,
  FlexContainer,
  HeadingWrapper,
  OverlayContainer,
} from './styled';
import Reaction from '@/components/_root/card/reaction';
import BlockContent from '@/components/block-content';
import SectionTitle from '../section-title';
import BookDownloadModal from '../book-download-modal';
interface ProjectProps {
  projectType?: string;
  client?: string;
  liveDate?: string;
}

interface Reactions {
  like: string;
  celebrate: string;
  love: string;
}

interface Props {
  title: { outlined?: string; solid?: string; inline?: boolean; isOutlinedFirst?: boolean };
  imageUrl: string;
  isGradient?: boolean;
  backgroundUrl?: string | any;
  reactionStats?: Reactions;
  showProject?: boolean;
  project?: ProjectProps;
  imageMargin?: boolean;
  body?: ReactNode;
  downloadBookButton?: { url: string; title: string };
  getInTouchButton?: { url: string; title: string };
  page?: 'TEAM_MEMBER' | 'WORK' | 'WORK_PROJECT' | 'ARTICLE' | 'BLOG_HUB';
  padding?: 'large' | 'small';
  imageWidth?: string;
  bannerImgClass?: string;
  isDetailsPage?: boolean;
  downloadButtonUrl?: string;
}

interface PageBannerProps {
  text: ReactNode;
  downloadBookButton?: { url: string; title: string };
  getInTouchButton?: { url: string; title: string };
  setShowBookFormModal?: (val: boolean) => void;
}

const PageBanner = ({
  title,
  imageUrl,
  isGradient = true,
  reactionStats,
  showProject = false,
  project,
  imageMargin = false,
  body,
  downloadBookButton,
  getInTouchButton,
  padding = 'large',
  page = 'TEAM_MEMBER',
  imageWidth,
  bannerImgClass = '',
  isDetailsPage = false,
  downloadButtonUrl,
}: Props): JSX.Element => {
  const [showBookFormModal, setShowBookFormModal] = React.useState<boolean>(false);

  return (
    <>
      <OverlayContainer page={page}>
        <Container className="relative h-full z-10">
          <FlexContainer>
            <div className={`pt-16 pb-32 lg:pt-28 lg:pb-36 ${!isDetailsPage ? `lg:w-8/12` : ``}`}>
              <HeadingWrapper title={title}>
                {reactionStats && (
                  <div className="pb-5">
                    <Reaction size="large" reactionStats={reactionStats} isGradient={isGradient} />
                  </div>
                )}
                <SectionTitle
                  title={{ ...title, isOutlinedFirst: title.isOutlinedFirst }}
                  mode="dark"
                  size={page === 'WORK_PROJECT' || page === 'ARTICLE' ? 'h3' : 'h1'}
                />
              </HeadingWrapper>
              {body && (
                <div className={`relative z-11`}>
                  <PageBanner.Body
                    text={body}
                    downloadBookButton={downloadBookButton}
                    getInTouchButton={getInTouchButton}
                    setShowBookFormModal={setShowBookFormModal}
                  />
                </div>
              )}
            </div>
          </FlexContainer>
        </Container>
        <BannerImageSection
          imageUrl={imageUrl}
          showProject={showProject}
          project={project}
          imageMargin={imageMargin}
          padding={padding}
          imageWidth={imageWidth}
          className={bannerImgClass}
          page={page}
          containerClass={`${page === 'WORK' ? `order-last` : ``}`}
        />
      </OverlayContainer>
      <div className={`relative z-100`}>
        <BookDownloadModal
          isOpen={showBookFormModal}
          onClose={(): void => setShowBookFormModal(false)}
          downloadLink={downloadButtonUrl}
        />
      </div>
    </>
  );
};

export default PageBanner;

PageBanner.Body = ({
  text,
  downloadBookButton,
  getInTouchButton,

  setShowBookFormModal,
}: PageBannerProps): JSX.Element => (
  <BodyWrapper>
    <BodyTextContent>
      <BlockContent blocks={text} />
    </BodyTextContent>
    <BodyButtonWrapper>
      <Button type="primary" mode="light" size="large" href={getInTouchButton?.url}>
        {getInTouchButton?.title ?? 'Get in touch'}
      </Button>
      <Button
        type="secondary"
        mode="dark"
        size="large"
        onClick={(): void => setShowBookFormModal(true)}
      >
        {downloadBookButton?.title ?? 'Download the book of fame'}
      </Button>
    </BodyButtonWrapper>
  </BodyWrapper>
);
