import React, { ReactNode } from 'react';
import { Image } from '@/components/_root';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './style.module.css';

const MAX_HEIGHT = 580;

interface Props {
  children: ReactNode;
}

interface Overlay extends Props {
  page?: 'TEAM_MEMBER' | 'WORK' | 'WORK_PROJECT' | 'ARTICLE' | 'BLOG_HUB';
}

interface Header extends Props {
  title: { outlined?: string; solid?: string };
}

interface ProjectProps {
  projectType?: string;
  client?: string;
  liveDate?: string;
}

interface BannerImageProps {
  imageUrl: string;
  showProject?: boolean;
  project?: ProjectProps;
  imageMargin?: boolean;
  padding?: 'large' | 'small';
  imageWidth?: string;
  className?: string;
  page?: string;
  containerClass?: string;
}

export const OverlayContainer = ({ children, page }: Overlay): JSX.Element => (
  <div
    className={`w-full bg-center relative overflow-hidden`}
    style={{ background: page === 'ARTICLE' ? '#181818' : '', minHeight: MAX_HEIGHT }}
  >
    {page === 'TEAM_MEMBER' ||
      (page === 'WORK' && (
        <StaticImage
          src="../../../assets/img/connect-with-carrie-overlay.png"
          alt=""
          className="w-full z-0 h-full"
          style={{ position: 'absolute' }}
          placeholder="blurred"
        />
      ))}
    {page === 'WORK_PROJECT' && (
      <StaticImage
        src="../../../assets/img/work-project-bg.png"
        alt=""
        className="w-full z-0 h-full"
        style={{ position: 'absolute' }}
        placeholder="blurred"
      />
    )}
    {page === 'BLOG_HUB' && (
      <StaticImage
        src="../../../assets/img/blog-hub-banner.png"
        alt=""
        className="w-full z-0 h-full"
        style={{ position: 'absolute' }}
        placeholder="blurred"
      />
    )}
    <div className="relative z-10 pt-10" style={{ minHeight: 'inherit' }}>
      {children}
    </div>
  </div>
);

export const FlexContainer = ({ children }: Props): JSX.Element => {
  return <div className="pt-16 md:pt-20 flex flex-col lg:flex-row relative">{children}</div>;
};

export const HeadingWrapper = ({ title, children }: Header): JSX.Element => {
  const headerClass = ` ${title.outlined ? 'lg:w-full' : 'lg:w-9/12'}`;
  return <div className={headerClass}>{children}</div>;
};

export const BodyWrapper = ({ children }: Props): JSX.Element => {
  return <div className="pt-8">{children}</div>;
};

export const BodyTextContent = ({ children }: Props): JSX.Element => {
  return <div className="w-11/12 lg:w-10/12 text-white">{children}</div>;
};

export const BodyButtonWrapper = ({ children }: Props): JSX.Element => {
  return (
    <div className="pt-8 md:pb-8 lg:pb-0 flex flex-col md:flex-row space-y-6 md:space-y-0 md:space-x-6">
      {children}
    </div>
  );
};

export const BannerImageSection = ({
  imageUrl,
  imageMargin,
  padding = 'large',
  imageWidth,
  className = '',
  page,
  containerClass = '',
}: BannerImageProps): JSX.Element => {
  let imageClass = ` ${imageMargin ? '' : `${styles.imageSection}`}`;

  let containerClasses = `${containerClass} z-0`;
  if (page === 'BLOG_HUB') {
    containerClasses =
      containerClasses + ' hidden lg:flex absolute top-0 bottom-0 right-0 h-full opacity-60';
  } else if (page === 'WORK') {
    containerClasses =
      containerClasses +
      ' flex w-full lg:w-auto lg:absolute lg:top-20 lg:-bottom-8 lg:right-0 h-96 lg:h-full pt-8 lg:pt-0';
  } else if (page === 'ARTICLE' || page === 'WORK_PROJECT') {
    containerClasses = containerClasses + ` absolute top-0 bottom-0 right-0 left-0`;
    imageClass = imageClass + ` h-full`;
  }

  return (
    <div className={containerClasses}>
      <Image
        src={imageUrl}
        alt="Mobile"
        className={`${imageClass} ${className}`}
        objectFit={page === 'WORK' ? 'contain' : 'cover'}
      />
    </div>
  );
};

export const ProjectType = ({ projectType, client, liveDate }: ProjectProps): JSX.Element => {
  const tailwindClass =
    'w-10/12 md:w-56 lg:w-64 rounded-md bg-dominant text-white absolute -bottom-64 md:-bottom-60 md:right-4 lg:right-10 xl:-right-12 z-20';
  return (
    <div className={tailwindClass}>
      <div className="flex flex-col space-y-8 pt-7 pb-8 px-8">
        <div>
          <p>Project type</p>
          <h4 className="pt-2">{projectType}</h4>
        </div>
        <div>
          <p>Client</p>
          <h4 className="pt-2">{client}</h4>
        </div>
        <div>
          <p>Live date</p>
          <h4 className="pt-2">{liveDate}</h4>
        </div>
      </div>
    </div>
  );
};
