import { Image } from '@/components/_root';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

interface OverlayProps extends Props {
  backgroundImgUrl?: string;
  backgroundPosition?: string;
}

interface HeaderProps extends Props {
  className?: string;
}

interface ContentContainerProps extends Props {
  isDark?: boolean;
  className?: string;
}

export const OverlayContainer = ({
  children,
  backgroundImgUrl,
  backgroundPosition,
}: OverlayProps): JSX.Element => {
  return (
    <>
      <div className={`${backgroundImgUrl ? '' : 'hidden'} relative`}>
        <Image
          src={backgroundImgUrl}
          alt=""
          className="w-full h-full top-0 left-0 object-cover"
          style={{
            backgroundPosition: `${backgroundPosition}`,
            position: 'absolute',
          }}
        />
        <div className="relative z-10">{children}</div>
      </div>
      <div className={`${backgroundImgUrl ? 'hidden' : 'bg-white'} w-full`}>{children}</div>
    </>
  );
};

export const HeaderTextContainer = ({ className = '', children }: HeaderProps): JSX.Element => {
  return (
    <div className={`flex flex-col md:flex-row justify-center gap-4 pb-6 ${className}`}>
      {children}{' '}
    </div>
  );
};

export const ContentContainer = ({
  isDark,
  className = '',
  children,
}: ContentContainerProps): JSX.Element => {
  return (
    <div className={`flex justify-center ${isDark ? 'text-white' : ''} ${className}`}>
      {children}{' '}
    </div>
  );
};
