import React, { ReactNode } from 'react';
import { Container, HeaderText, PageSection } from '@/components/_root';
import { ContentContainer, HeaderTextContainer, OverlayContainer } from './styled';

interface Props {
  title: {
    solid?: string;
    outlined?: string;
    isOutlinedFirst?: boolean;
    isInline?: boolean;
    className?: string;
  };
  headerClass?: string;
  contentClass?: string;
  isDark?: boolean;
  body?: ReactNode;
  backgroundImgUrl?: string;
  backgroundPosition?: string;
}

const Faporbaji = ({
  title,
  body,
  isDark = false,
  backgroundImgUrl,
  headerClass,
  contentClass,
  backgroundPosition = 'center',
}: Props): JSX.Element => {
  return (
    <OverlayContainer backgroundImgUrl={backgroundImgUrl} backgroundPosition={backgroundPosition}>
      <PageSection paddingAmount="small" mode={`${isDark && !backgroundImgUrl ? 'dark' : 'light'}`}>
        <Container>
          <HeaderTextContainer className={headerClass}>
            {!title.outlined ? (
              <HeaderText size="h2" mode={`${isDark ? 'dark' : 'light'}`}>
                {title.solid}
              </HeaderText>
            ) : (
              <>
                <HeaderText
                  mode={`${isDark ? 'dark' : 'light'}`}
                  size="h2"
                  className={`${title.isOutlinedFirst ? 'order-2' : ''} ${
                    title.isInline ? 'inline' : ''
                  } ${title.className}`}
                >
                  {title.solid}
                </HeaderText>
                <HeaderText.Outline
                  mode={`${isDark ? 'dark' : 'light'}`}
                  size="h2"
                  className={`${title.isInline ? 'inline' : ''} ${title.className}`}
                >
                  {title.outlined}
                </HeaderText.Outline>
              </>
            )}
          </HeaderTextContainer>
          <ContentContainer isDark={isDark} className={contentClass}>
            {body}
          </ContentContainer>
        </Container>
      </PageSection>
    </OverlayContainer>
  );
};

export default Faporbaji;
