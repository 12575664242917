import React from 'react';
import { graphql, PageProps } from 'gatsby';

import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import PageBanner from '@/components/blog-hub-page-banner';
import WorkProjectBody from '@/components/work-project-body';
import Faporbaji from '@/components/faporbaji';
import CaseStudiesSlider from '@/components/case-studies-slider';
import { getTitle } from '@/utils/helper/getTitle';
import { ResultLikeThis } from '@/components/project-result-like-this';

const WorkProject = ({ data, location, pageContext }: PageProps<any>): JSX.Element => {
  const {
    // @ts-ignore
    breadcrumb: { crumbs },
  } = pageContext;

  return (
    <Layout location={location} crumbs={crumbs} crumbLabel={data?.sanityOurWorks?.metaInfo?.pageMetaName || data?.sanityBlog?.title}>
      <Seo
        title={
          data?.sanityOurWorks?.metaInfo?.pageMetaName || data?.sanityBlog?.title || 'Case Study'
        }
        description={data?.sanityOurWorks?.metaInfo?.pageMetaDescription}
        pageMetadata={data?.sanityOurWorks?.metaInfo?.metadata}
        canonical={data?.sanityOurWorks?.metaInfo?.canonical}
      />
      <PageBanner
        title={{
          solid: data?.sanityOurWorks?.banner_title?.title_solid,
          outlined: data?.sanityOurWorks?.banner_title?.title_outlined,
          isOutlinedFirst: data?.sanityOurWorks?.banner_title?.is_outline_first,
          inline: false,
        }}
        imageUrl={data?.sanityOurWorks?.banner}
        isGradient={false}
        showProject={true}
        project={{
          projectType: data?.sanityOurWorks?.category?.title,
          client: data?.sanityOurWorks?.client,
          liveDate: data?.sanityOurWorks?.live_date,
        }}
        page="WORK_PROJECT"
        isDetailsPage
      />
      <WorkProjectBody
        title={getTitle(data?.sanityOurWorks)}
        body={data?.sanityOurWorks?._rawBody}
        project={{
          projectType: data?.sanityOurWorks?.category?.title,
          client: data?.sanityOurWorks?.client,
          liveDate: data?.sanityOurWorks?.live_date,
        }}
        insights={data?.sanityOurWorks?.insights}
      />
      <Faporbaji
        backgroundImgUrl={data?.RESULT_IMAGE}
        backgroundPosition="top"
        isDark={true}
        title={{
          solid: data?.sanityOurWorks?.work_project_result?.title ?? 'WANT RESULTS LIKE THESE?',
        }}
        headerClass="md:w-10/12 mx-auto text-center"
        body={
          <ResultLikeThis
            description={data?.sanityOurWorks?.work_project_result?.description}
            button={{ url: '/contact' }}
          />
        }
        contentClass="pt-4 flex-col items-center space-y-4 md:w-6/12 mx-auto text-center"
      />
      <CaseStudiesSlider
        sectionTitle={{
          solid: `More ${data?.sanityOurWorks?.category?.title}`,
          outlined: `Case Studies`,
          isInline: true,
        }}
        caseStudies={data?.allSanityOurWorks?.edges}
        containerClasses={`py-16 lg:py-32`}
      />
    </Layout>
  );
};

export default WorkProject;

export const query = graphql`
  query sanityOurWorks($id: String!, $category: String!) {
    sanityOurWorks(id: { eq: $id }) {
      id
      slug {
        current
      }
      metaInfo {
        pageMetaDescription
        pageMetaName
        metadata {
          metaContent
          metaName
        }
        canonical
      }
      title {
        title_solid
        title_outlined
        is_outline_first
      }
      banner_title {
        title_solid
        title_outlined
        is_outline_first
      }
      client
      live_date
      excerpt
      case_study_title
      insights {
        label
        value
      }
      category {
        title
      }
      _rawBody
      banner {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      thumbnail {
        asset {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      work_project_result {
        title
        description
        button {
          url
          title
        }
      }
    }

    allSanityOurWorks(filter: { category: { title: { eq: $category } } }) {
      edges {
        node {
          id
          slug {
            current
          }
          title {
            title_solid
            title_outlined
            is_outline_first
          }
          excerpt
          case_study_title
          category {
            title
          }
          _rawBody
          banner {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          thumbnail {
            asset {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
    RESULT_IMAGE: file(relativePath: { eq: "riser-bg.png" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`;
