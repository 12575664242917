import React, { ReactNode } from 'react';
import { Container, PageSection } from '@/components/_root';
import { HeaderTextContainer } from './styled';
import BlockContent from '@/components/block-content';
import SectionTitle from '../section-title';
import ProjectInsights from '@/components/project-insights';

interface ProjectProps {
  projectType?: string;
  client?: string;
  liveDate?: string;
}

interface Props {
  title: { solid?: string; outlined?: string; isSolidFirst?: boolean };
  body: ReactNode;
  project?: ProjectProps;
  insights?: { value: string; label: string }[];
}

const WorkProjectBody = ({ title, body, project, insights }: Props): JSX.Element => (
  <PageSection noScrollReveal>
    <Container className={`relative`}>
      <div
        className={`relative md:absolute md:right-0 top-0 -mt-40 w-full md:w-56 z-10 bg-black text-white rounded`}
      >
        <div className="flex flex-col space-y-8 pt-7 pb-8 px-8">
          <div>
            <p>Project type</p>
            <h4 className="pt-2">{project?.projectType}</h4>
          </div>
          <div>
            <p>Client</p>
            <h4 className="pt-2">{project?.client}</h4>
          </div>
          <div>
            <p>Live date</p>
            <h4 className="pt-2">{project?.liveDate}</h4>
          </div>
        </div>
      </div>
      <HeaderTextContainer>
        <SectionTitle title={title} />
      </HeaderTextContainer>
      <div className={`contentBody`}>
        <BlockContent blocks={body} />
      </div>
      {!!insights?.length && <ProjectInsights insights={insights} />}
    </Container>
  </PageSection>
);

export default WorkProjectBody;
