import React from 'react';
import { IconLike, IconCelebrate, IconLove } from '@/components/_icons';
import getGradientSteps from '@/utils/helper/getGradientSteps';

const iconSize = {
  large: '30',
  small: '24',
  auto: '',
};
export interface Props {
  size?: 'large' | 'small' | 'auto';
  reactionStats: {
    like: string;
    celebrate: string;
    love: string;
  };
  isGradient?: boolean;
}

export interface Icon {
  id: number;
  stat: string;
  element: JSX.Element;
}

const colors: string[] = getGradientSteps(['#9542F6', '#EA36A8'], 3);

const Reactions = ({ size = 'small', reactionStats, isGradient = true }: Props): JSX.Element => {
  const tailwindClass = 'flex flex-row space-x-4';
  const iconDivClass = 'flex justify-center items-center space-x-2';

  const iconsData: Array<Icon> = [
    {
      id: 1,
      stat: reactionStats?.like ?? '0',
      element: (
        <IconLike
          fillColor={isGradient ? colors[0] : 'white'}
          iconSize={size ? iconSize[size] : '36'}
        />
      ),
    },
    {
      id: 2,
      stat: reactionStats?.celebrate ?? '0',
      element: (
        <IconCelebrate
          fillColor={isGradient ? colors[1] : 'white'}
          iconSize={size ? iconSize[size] : '36'}
        />
      ),
    },
    {
      id: 3,
      stat: reactionStats?.love ?? '0',
      element: (
        <IconLove
          fillColor={isGradient ? colors[2] : 'white'}
          iconSize={size ? iconSize[size] : '36'}
        />
      ),
    },
  ];

  return (
    <div className={tailwindClass}>
      {iconsData.map((data, index) => (
        <div
          style={{
            background: `${isGradient ? `${colors[index]}` : 'white'} `,
            WebkitTextFillColor: 'transparent',
            WebkitBackgroundClip: 'text',
          }}
          className={iconDivClass}
          key={data.id}
        >
          {data.element}
          <h5 className="mt-2">{data.stat}</h5>
        </div>
      ))}
    </div>
  );
};

export default Reactions;
