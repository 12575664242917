import React from 'react';
import { HeaderText } from '@/components/_root';

interface SectionTitleProps {
  title: { outlined?: string; solid?: string; isOutlinedFirst?: boolean };
  size?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  isGradient?: boolean;
  mode?: 'light' | 'dark';
  isInline?: boolean;
}

const SectionTitle = ({
  title,
  size = 'h2',
  isGradient,
  mode,
  isInline,
}: SectionTitleProps): JSX.Element => {
  if (title.isOutlinedFirst)
    return (
      <div className={'section-header'}>
        <HeaderText.Outline
          mode={mode || (isGradient ? 'dark' : 'light')}
          size={size}
          className={isInline ? 'md:inline' : ''}
        >
          {title.solid}
        </HeaderText.Outline>
        <HeaderText
          mode={mode || (isGradient ? 'dark' : 'light')}
          size={size}
          className={isInline ? 'md:inline' : ''}
        >
          {title.outlined}
        </HeaderText>
      </div>
    );
  return (
    <div className={'section-header'}>
      <HeaderText
        mode={mode || (isGradient ? 'dark' : 'light')}
        size={size}
        className={isInline ? 'md:inline' : ''}
      >
        {title.solid}
      </HeaderText>
      <HeaderText.Outline
        mode={mode || (isGradient ? 'dark' : 'light')}
        size={size}
        className={isInline ? 'md:inline' : ''}
      >
        {title.outlined}
      </HeaderText.Outline>
    </div>
  );
};

export default SectionTitle;
