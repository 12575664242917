interface ITitle {
  outlined: string;
  solid: string;
  isSolidFirst?: boolean;
  isOutlinedFirst?: boolean;
}

interface DataArgs {
  title: {
    title_outlined: string;
    title_solid: string;
    is_outline_first?: boolean;
  };
}

export const getTitle = (data: DataArgs): ITitle => {
  return {
    outlined: data?.title?.title_outlined,
    solid: data?.title?.title_solid,
    isOutlinedFirst: data?.title?.is_outline_first,
  };
};
